import { template as template_3aa07ba1ec484f118ff7d3ec8b6b220e } from "@ember/template-compiler";
const SidebarSectionMessage = template_3aa07ba1ec484f118ff7d3ec8b6b220e(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
