import { template as template_8ddc999f2e5d41d58ada7226d7fa5429 } from "@ember/template-compiler";
const FKControlMenuContainer = template_8ddc999f2e5d41d58ada7226d7fa5429(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
