import { template as template_6371fe23dad542939595fe24f2e2b1ed } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_6371fe23dad542939595fe24f2e2b1ed(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
